.contact .row {
  border: 0.5rem solid rgba(213, 207, 207, 0.2);
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 1rem;
  background-color: white;
}

.contact .row form {
  flex: 1 1 50rem;
  padding: 0.5rem 1rem;
}

.contact .row .map {
  flex: 1 1 30rem;
  width: 100%;
  padding: 1rem;
}

.contact .row form .inputBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact .row form textarea,
.contact .row form .inputBox input {
  margin-top: 1rem;
  padding: 1rem;
  font-size: 1.7rem;
  background: white;
  border-radius: 0.5rem;
  text-transform: none;
  width: 49%;
}

.contact .row form textarea {
  width: 100%;
  height: 20rem;
  resize: none;
}

.contact .row form textarea:focus,
.contact .row form .inputBox input:focus {
  background-color: rgb(247, 227, 237);
}

.inputSubmit {
  margin-top: 1rem;
  height: 3rem;
  width: 15rem;
  font-size: 1.6rem;
}
